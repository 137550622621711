body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*.player-wrapper {*/
/*  position: relative;*/
/*  padding-top: 56.25%; !* Player ratio: 100 / (1280 / 720) *!*/
/*}*/

/*.react-player {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/

/*.player-wrapper {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  padding-top: 56.25%; !* Player ratio: 100 / (1280 / 720) *!*/
/*  overflow: hidden;*/
/*}*/

/*.react-player {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/